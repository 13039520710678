<template>
  <AppLayout>
    <router-view></router-view>
  </AppLayout>
</template>
<script>
import AppLayout from "./layouts/AppLayout.vue";
import { mapActions } from "vuex";
export default {
  components: {
    AppLayout,
  },
  async mounted() {
    // const response = await this.userValidate();
    // if (response && response.data && !response.data.success) {
    //   this.$cookies.remove("_token");
    // }

    if (
      this.$cookies.get("banned_until") &&
      this.$auth.user.status != "active"
    ) {
      this.logoutUser();
    }

    await this.getMaintenanceStatus();
  },
  methods: {
    ...mapActions("user", ["userValidate", "logout", "getMaintenanceStatus"]),

    async logoutUser() {
      this.$cookies.remove("_token");
      this.$cookies.remove("banned_until");
      await this.logout();
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url(./assets/css/AvenirLTStd35Light.otf) format("truetype");
  /* url(./assets/css/AvenirLTStd-Roman.woff) format("truetype"); */
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background:#10182a;
}

::-webkit-scrollbar-thumb {
  background: #223150;
  border-radius: 50px;
  /* #10182a */
}

::-webkit-scrollbar-thumb:hover {
  background: #223150;
}

#bettech .bt12,
.bt13,
.bt14 {
  display: none !important;
  -webkit-display: none !important;
}
#bt-inner-page .bt13 {
  display: none !important;
}

#bt-inner-page .bt12 {
  display: none !important;
}
</style>
